.benefitCard {
    position: relative;
    width: 80%;
    margin: 20px auto;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px 2px rgba(142, 68, 68, 0.2);
    
}

.benefitCard i {
    font-size:55px;
    color: rgb(232, 69, 69);
    margin: 18px 0 1px 0 ;
    position: absolute;
    right: 50%;
    bottom: 100%;
    transform: translate(50%,50%);
}

.benefitCard h2{
    color: rgb(232, 69, 69);
    margin: 8px;
    padding-top: 30px;
}

.benefitCard p{
    padding: 0 12px;
    margin: 12px;
    font-size:1rem;
    line-height: 1.7rem;
}

