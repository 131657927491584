body {
    margin: 0;
    padding: 0;
  }
  
  .App {
    font-family: "Ysra";
    text-align: center;
    background-color: rgb(249, 249, 249);
  }
  
  html {
    scroll-behavior: smooth;
  }
  