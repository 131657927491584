.hero_container {
  width: 100%;
  margin-top: 20px;
  
}

.hero_container .slagen{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    
}

.hero_container .slagen h1{
    font-size: 1.2em;
    margin-bottom: 8px;
}

.hero_container .slagen span{
    font-size: 1em;
}

.hero_container .slagen a {
    width: 100px;
    margin: 16px auto;
    border: none;
    background-color: rgb(232, 69, 69);
    padding: 12px;
    border-radius: 60px;
    font-weight: bold;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}

.hero_container .slagen a:hover {
    background-color: white;
    border: solid rgb(232, 69, 69);
    color: black;
}

.hero_container img {
  width: 60%;
}

.slide-in{
    animation: slideMe .9s ease-in;
}

@keyframes slideMe{
    0%{
        transform: translateX(500px);
    }
    100%{
        transform: skew(0deg);
    }
}

@media (min-width: 900px){
    .hero_container .slagen h1{
        font-size: 2em;
        font-weight: bolder;
    }
    
    .hero_container .slagen span{
        font-size: 1.5em;
    }
}