nav {
  background-color: rgb(233, 227, 227);
  filter: drop-shadow(4px 1px 20px rgb(255, 255, 255));
  box-shadow: 2px 3px 15px 0px rgba(0, 0, 0, 0.5);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 30;
}

.contact {
  display: flex;
  justify-content: space-around;
  padding: 8px;
  background-color: black;
}

.contact a {
  direction: none;
  color: white;
  font-weight: bold;
}

nav .logo {
  width: 180px;
}

@media screen and (min-width: 550px) {
  nav .logo {
    width: 200px;
  }
  .contact {
    justify-content: flex-end;
  }

  .contact a {
    margin: 0 20px;
  }
}
